<template>
	<div class="c-base-input-date">
		<input
			type="date"
			:name="config.name"
			:aria-label="config.label"
			:value="value"
			class="
				w-full
				h-full
				min-h-56
				bg-white
				px-24
				py-4
				overflow-hidden
				rounded-full
			"
			@change="getVal"
		/>
	</div>
</template>

<script>
export default {
	name: 'BaseInputDate',

	props: {
		id: String,
		ariaLabel: String,
		value: {
			type: String,
			default: '',
		},
		config: Object,
		rounded: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			string: null,
		};
	},

	methods: {
		getVal(event) {
			this.$emit('input', event.target.value);
		},
	},
};
</script>

<style lang="postcss"></style>
