import { camelCase, upperFirst } from 'lodash';

const DOCTYPES_BY_SOLUTION_AND_TEMPLATE = {
	// Plop: doctype - add solution
	main: {
		// Plop: doctype - add main doctype
		KultuNautEventPage: () =>
			import(
				/* webpackChunkName: "main__doctypeKultuNautEventPage" */ '~/doctypes/main/KultuNautEventPageCustom.vue'
			),
		KultuNautOverviewPage: () =>
			import(
				/* webpackChunkName: "main__doctypeKultuNautOverviewPage" */ '~/doctypes/main/KultuNautOverviewPageCustom.vue'
			),
		SubscriptionConfirmationPage: () =>
			import(
				/* webpackChunkName: "main__doctypeSubscriptionConfirmationPage" */ '~/doctypes/main/SubscriptionConfirmationPageCustom.vue'
			),
		RecyclingSortingABCPage: () =>
			import(
				/* webpackChunkName: "main__doctypeRecyclingSortingAbcPage" */ '~/doctypes/main/RecyclingSortingAbcPageCustom.vue'
			),
		SubscriptionPage: () =>
			import(
				/* webpackChunkName: "main__doctypeSubscriptionPage" */ '~/doctypes/main/SubscriptionPageCustom.vue'
			),
		SearchPage: () =>
			import(
				/* webpackChunkName: "main__doctypeSearchPage" */ '~/doctypes/main/SearchPageCustom.vue'
			),
		BookTheMayorPage: () =>
			import(
				/* webpackChunkName: "main__doctypeBookTheMayor" */ '~/doctypes/main/BookTheMayorCustom.vue'
			),
		AgendaPage: () =>
			import(
				/* webpackChunkName: "main__doctypeAgendaPage" */ '~/doctypes/main/AgendaPageCustom.vue'
			),
		AgendaList: () =>
			import(
				/* webpackChunkName: "main__doctypeAgendaList" */ '~/doctypes/main/AgendaListCustom.vue'
			),
		JobPage: () =>
			import(
				/* webpackChunkName: "main__doctypeJobPage" */ '~/doctypes/main/JobPageCustom.vue'
			),
		FrontPage: () =>
			import(
				/* webpackChunkName: "main__doctypeFrontPage" */ '~/doctypes/main/FrontPageCustom.vue'
			),
		CouncilMemberList: () =>
			import(
				/* webpackChunkName: "main__doctypeCouncilMemberList" */ '~/doctypes/main/CouncilMemberListCustom.vue'
			),
		JobList: () =>
			import(
				/* webpackChunkName: "main__doctypeJobList" */ '~/doctypes/main/JobListCustom.vue'
			),
		SelfServicePage: () =>
			import(
				/* webpackChunkName: "main__doctypeSelfServicePage" */ '~/doctypes/main/SelfServicePageCustom.vue'
			),
		SubjectList: () =>
			import(
				/* webpackChunkName: "main__doctypeSubjectList" */ '~/doctypes/main/SubjectListCustom.vue'
			),
		SubjectPage: () =>
			import(
				/* webpackChunkName: "main__doctypeSubjectPage" */ '~/doctypes/main/SubjectPageCustom.vue'
			),
		SelfServiceList: () =>
			import(
				/* webpackChunkName: "main__doctypeSelfServiceList" */ '~/doctypes/main/SelfServiceListCustom.vue'
			),
		OverviewList: () =>
			import(
				/* webpackChunkName: "main__doctypeOverviewList" */ '~/doctypes/shared/OverviewListCustom.vue'
			),
		NewsPage: () =>
			import(
				/* webpackChunkName: "main__doctypeNewsPage" */ '~/doctypes/shared/NewsPageCustom.vue'
			),
		PressReleasePage: () =>
			import(
				/* webpackChunkName: "main__doctypeNewsPage" */ '~/doctypes/shared/NewsPageCustom.vue'
			),
		NewsList: () =>
			import(
				/* webpackChunkName: "main__doctypeNewsList" */ '~/doctypes/shared/NewsListCustom.vue'
			),
		EventList: () =>
			import(
				/* webpackChunkName: "main__doctypeEventList" */ '~/doctypes/shared/EventListCustom.vue'
			),
		EventPage: () =>
			import(
				/* webpackChunkName: "main__doctypeEventPage" */ '~/doctypes/shared/EventPageCustom.vue'
			),
		ContentPage: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/shared/ContentPageCustom.vue'
			),
		SamlesiteInstitutioner: () =>
			import(
				/*  webpackChunkName: "main__doctypeInstitutionalPage" */ '~/doctypes/main/InstitutionPageCustom.vue'
			),

		InstitutionalNewsPage: () =>
			import(
				/* webpackChunkName: "main__doctypeNewsPage" */ '~/doctypes/shared/NewsPageCustom.vue'
			),
	},
	subsite: {
		// Plop: doctype - add subsite doctype
		SearchPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeSearchPage" */ '~/doctypes/subsite/SearchPageCustom.vue'
			),
		FrontPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeFrontPage" */ '~/doctypes/subsite/FrontPageCustom.vue'
			),
		OverviewListSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeOverviewList" */ '~/doctypes/shared/OverviewListCustom.vue'
			),
		NewsPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeNewsPage" */ '~/doctypes/shared/NewsPageCustom.vue'
			),
		NewsListSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeNewsList" */ '~/doctypes/shared/NewsListCustom.vue'
			),
		ContentPageSubsite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeContentPage" */ '~/doctypes/shared/ContentPageCustom.vue'
			),
		EventListSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeEventList" */ '~/doctypes/shared/EventListCustom.vue'
			),
		EventPageSubSite: () =>
			import(
				/* webpackChunkName: "subsite__doctypeEventPage" */ '~/doctypes/shared/EventPageCustom.vue'
			),
	},
};

export const getDoctypeFromSolutionAndTemplate = (solution, template) =>
	upperFirst(camelCase(`${solution}${template}`));

// format that allows local registration via `components`
export default Object.entries(DOCTYPES_BY_SOLUTION_AND_TEMPLATE).reduce(
	(acc, [solution, doctypesByTemplate]) => ({
		...acc,

		...Object.entries(doctypesByTemplate).reduce(
			(acc, [template, doctype]) => ({
				...acc,

				[getDoctypeFromSolutionAndTemplate(solution, template)]:
					doctype,
			}),
			{}
		),
	}),
	{}
);
